import { Button } from '../../../components/ui/button';
import { FiCopy } from 'react-icons/fi';

interface BtnInsertNotesProps {
  handleInsert: () => void;
}

export const BtnInsertNotes = ({ handleInsert }: BtnInsertNotesProps) => {
  return (
    <Button
      variant='outline'
      size='icon'
      onClick={handleInsert}
      data-tip={'Copy from previous notes'}
    >
      <FiCopy />
    </Button>
  );
};

import { CellObject } from '../features/overview-table/hooks/use-table';
import ReactDOMServer from 'react-dom/server';
import { displayDate } from '../helpers/dateHelpers';

type Change = {
  days: number;
  change: {
    roomName: string;
    value: number;
  };
};

type RateShop = {
  arrivalDate: string;
  best_flex: boolean;
  cancellable: boolean;
  cityTaxIncl: boolean;
  currency: string;
  extractDateTime: string;
  hotelId: number;
  hotelName: string;
  los: number;
  maxPersons: number;
  message: string;
  otherTaxesIncl: boolean;
  roomName: string;
  shopCurrency: string;
  value: number;
  vatIncl: boolean;
  changes?: Change[];
};

type RendererProps = {
  cell: CellObject;
};

const RateShopToolTip = (cell: CellObject): JSX.Element => {
  const rate = cell.meta?.data as RateShop;
  if (rate) {
    return (
      <ul>
        <li>
          <span className='font-bold'>Stay Date:</span>{' '}
          {displayDate(rate.arrivalDate)}
        </li>
        <li>
          <span className='font-bold'>Shop Date:</span>{' '}
          {displayDate(rate.extractDateTime)}
        </li>
        <li>
          <span className='font-bold'>Rate:</span> {cell.displayValue}
        </li>
        <li>
          <span className='font-bold'>Room Name:</span> {rate.roomName}
        </li>
        <li>
          <span className='font-bold'>LOS:</span> {rate.los}
        </li>
        <li>
          <span className='font-bold'>Best Flex?:</span>{' '}
          {String(rate.best_flex)}
        </li>
        {rate.changes && rate.changes.length > 0 && (
          <li>
            <span className='font-bold'>Rate Changes</span>
            <ul>
              {rate.changes.map((change: Change) => (
                <li key={`rate-change-${change.days}`}>
                  {change.days} day ago: {change.change.value}
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    );
  } else {
    return <></>;
  }
};

const RateShopCell = (cell: CellObject) => {
  const { dataKey, value, displayValue } = cell;

  return (
    <>
      <span
        data-html={true}
        data-type='light'
        data-tip={ReactDOMServer.renderToString(<RateShopToolTip {...cell} />)}
      >
        {value === 0
          ? renderMessage(cell.meta?.data?.message)
          : dataKey === 'rates.0'
          ? value
          : displayValue}
      </span>
    </>
  );
};

export const renderMessage = (message?: string) => {
  switch (message) {
    // No rates shopped
    case 'general.missing':
      return 'N/A';
    // no availability
    case 'rates.soldout':
      return 'Sold Out';
    //no Best Flex Rate Found
    case 'rates.nobar':
      return 'No BstFlx';
    //No rates for 1 person found
    case 'rates.no_person.1':
      return 'No 1P';
    //Mealtype breakfast is missing
    case 'rates.meal.missing.1':
      return 'No Bfst';
    //Roomtype is missing
    case 'rates.roomtypes.unavailable':
      return 'No RmType';
    //Only availability for LOS2 or above
    case 'rates.restrictionlos2':
      return 'LOS2';
    //Only availability for LOS3 or above
    case 'rates.restrictionlos3':
      return 'LOS3';
    default:
      return message;
  }
};
export default function RenderRateShop(props: RendererProps) {
  const { cell } = props;
  return <RateShopCell {...cell} />;
}

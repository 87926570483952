import { CellObject, useTable } from '../../../overview-table/hooks/use-table';

import { DateHelpers } from '../../../../helpers/dateHelpers';
import { OtaiLowestRatesQuery } from '../../../overview-table/gql/_gen_/rate-shop.gql';
import { OtaiRate } from '../../../../graphql/types';
import { ReportDataQuery } from '../../gql/_gen_/report.gql';
import { SectionContainer } from '../section-container';
import Table from '../../../../components/Table';
import { renderMessage } from '../../../../renderers/RenderRateShop';

function RateCellRenderer(cell: CellObject) {
  const extractedClass = cell.cls.match(/\btext-[a-zA-Z0-9-]+\b/)?.[0];
  const rateDetails: OtaiRate | undefined = cell.meta?.data;

  const cellClass = `text-left ${extractedClass}`;

  // Extract common JSX into a variable
  const commonJSX = (
    <div className={cellClass}>
      {cell.dataKey === 'rates.0' ? cell.value : cell.displayValue}
    </div>
  );

  // Return common JSX if cell is of type 'rates.0' or if there are no rate details
  if (
    cell.dataKey === 'rates.0' ||
    !rateDetails ||
    rateDetails?.changes?.length === 0
  ) {
    return commonJSX;
  }

  const displayValue =
    cell.displayValue === ''
      ? renderMessage(cell.meta?.data?.message)
      : cell.displayValue;

  const change =
    rateDetails && rateDetails.changes ? rateDetails.changes[0] : undefined;

  const previousRate = change?.change?.value ?? 0;

  const priceChange =
    previousRate === 0
      ? renderMessage(cell.meta?.data?.message) ?? 'NA'
      : Math.round((rateDetails?.value ?? 0) - previousRate);

  // Return common JSX if priceChange is 0
  if (priceChange === 0) {
    return commonJSX;
  }

  const spanClass =
    typeof priceChange === 'string'
      ? 'text-orange-500'
      : priceChange > 0
      ? 'text-green-500'
      : 'text-red-500';
  const changeIcon =
    typeof priceChange === 'string' ? '' : priceChange > 0 ? '↑' : '↓';

  // Render JSX with price change and appropriate color class
  return (
    <div className={cellClass}>
      {cell.dataKey === 'rates.0' ? cell.value : displayValue}{' '}
      {priceChange === displayValue ? null : (
        <span className={spanClass}>
          {changeIcon}
          <sup>
            {typeof priceChange === 'number'
              ? Math.abs(priceChange)
              : priceChange}
          </sup>
        </span>
      )}
    </div>
  );
}

type FiveDayRatesProps = {
  dataRateShop?: OtaiLowestRatesQuery;
  dataReport?: ReportDataQuery;
  loadingRateShop: boolean;
  loadingReport: boolean;
  startDate: string;
};

export const FiveDayRates = ({
  dataRateShop,
  dataReport,
  loadingRateShop,
  loadingReport,
  startDate,
}: FiveDayRatesProps) => {
  const { compSet, dailyRates } = dataRateShop || {};
  const { rmData } = dataReport || {};

  const tableCols = compSet?.length
    ? compSet.filter((comp) => comp?.key !== 'rates.0')
    : [];

  const { headers: detailHeaders, rows: detailRows } = useTable({
    customHeaders: null,
    data: {
      dailyRates,
      compSet,
      rmData,
    },
    dateSegment: 'day',
    includeSummary: false,
    startDate: startDate,
    endDate: DateHelpers.addDays(startDate, 4),
    detailCols: [
      { dataSource: 'rmData', key: 'dow' },
      { dataSource: 'dailyRates', key: 'stay_date' },
      { dataSource: 'rmData', key: 'occ' },
      { dataSource: 'rmData', key: 'ooo' },
      {
        dataSource: 'rmData',
        key: 'delta_7day',
        tooltip: '7-day delta (change in rooms committed)',
      },
      ...tableCols,
    ],
  });

  const headers = detailHeaders.map((h) => h.title);
  const rows = detailRows.map((row) => row.map((cell) => cell));

  return (
    <SectionContainer
      anchorId='five-day-rates'
      title='5-day Rate Outlook (lowest non-qualified rate)'
    >
      {loadingRateShop || loadingReport ? (
        <span>Loading...</span>
      ) : (
        <Table
          customCellAlignment={'text-center 3xl:text-left'}
          headers={headers}
          rows={rows}
          renderer={RateCellRenderer}
        />
      )}
    </SectionContainer>
  );
};

import { CreateMonthlyBudgetInput, MonthlyBudget } from '../../graphql/types';
import { endOfYear, firstOfYear, getYear } from '../../helpers/dateHelpers';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCreateMonthlyBudgetMutation,
  useDeleteMonthlyBudgetMutation,
  useHotelMonthlyBudgetsQuery,
  useUpdateMonthlyBudgetMutation,
} from '../../features/budget/_gen_/budget.gql';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CurrencyCell } from '../../components/TableCells';
import DataTableContainer from '../DataTableContainer';
import Editable from '../../components/Editable';
import { MdDeleteForever } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import { getYearMonths } from '../../helpers/dateHelpers';
import numbro from 'numbro';
import { useHotel } from '../../context/hotelContext';
import { useUser } from '../../context/userContext';

type MonthRowProps = {
  data?: MonthlyBudget | null;
  month: { display: string; value: number };
  userId: string;
  hotelId: number;
  selected: boolean;
  onSelect: (id: string) => void;
  onDelete: (id: string) => void;
  onUpdate: (data: Partial<MonthlyBudget>) => void;
};

const MonthRow = memo(function MonthRow({
  data,
  month,
  hotelId,
  userId,
  selected,
  onSelect,
  onDelete,
  onUpdate,
}: MonthRowProps) {
  const {
    budget_sold: sold,
    budget_adr: adr,
    budget_revenue: revenue,
    id,
  } = data || {};
  const year = getYear();

  const handleChange = useCallback(
    (field: 'budget_sold' | 'budget_adr') => (value: string) => {
      onUpdate({
        id: id,
        hotel_id: hotelId,
        created_by_id: userId,
        stay_date: `${year}-${month.value.toString().padStart(2, '0')}-01`,
        [field]: parseFloat(value),
      });
    },
    [id, hotelId, userId, year, month.value, onUpdate]
  );

  return (
    <tr className='border-b border-gray-200 bg-gray-50 h-10'>
      <td className='w-24'>
        <div className='flex items-center ml-10'>
          {data && (
            <input
              type='checkbox'
              checked={selected}
              onChange={() => onSelect(data.id)}
              aria-label={`Select ${month.display}`}
            />
          )}
          {selected && (
            <button
              className='ml-2 text-xl text-red-700'
              onClick={() => data?.id && onDelete(data?.id)}
              aria-label={`Delete ${month.display}`}
            >
              <MdDeleteForever />
            </button>
          )}
        </div>
      </td>
      <td className='text-center py-2 font-medium text-gray-600'>
        {month.display}
      </td>
      <td className='text-center py-2 w-28'>
        <Editable
          initialValue={sold ? String(sold) : ''}
          inputTextSize='text-md'
          inputWidth='w-14'
          onChange={handleChange('budget_sold')}
          aria-label={`Sold for ${month.display}`}
        />
      </td>
      <td className='text-center py-2 w-28'>
        <Editable
          initialValue={
            adr
              ? numbro(adr).formatCurrency({
                  mantissa: 2,
                  thousandSeparated: true,
                })
              : ''
          }
          inputTextSize='text-md'
          inputWidth='w-14'
          onChange={handleChange('budget_adr')}
          aria-label={`ADR for ${month.display}`}
        />
      </td>
      <CurrencyCell
        value={revenue || (adr && sold ? adr * sold : '')}
        metric='revenue'
        style={['text-center py-2 text-blue-900 font-semibold']}
      />
    </tr>
  );
});

function Budget() {
  const { hotel } = useHotel();
  const { user } = useUser();
  const months = useMemo(() => getYearMonths(), []);
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: budgetData,
    loading: loadingBudget,
    refetch,
  } = useHotelMonthlyBudgetsQuery({
    skip: !hotel?.hotel_id,
    variables: {
      filters: {
        hotelId: Number(hotel?.hotel_id),
        startDate: firstOfYear(),
        endDate: endOfYear(),
      },
    },
  });

  const [createMonthlyBudget] = useCreateMonthlyBudgetMutation();
  const [updateMonthlyBudget] = useUpdateMonthlyBudgetMutation();
  const [deleteMonthlyBudget] = useDeleteMonthlyBudgetMutation();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selected]);

  const handleRowSelect = useCallback((id: string) => {
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  }, []);

  const handleSelectAllRows = useCallback(() => {
    setSelected((prev) =>
      prev.length === budgetData?.hotelMonthlyBudgets?.length
        ? []
        : budgetData?.hotelMonthlyBudgets?.map((b) => b?.id || '') || []
    );
  }, [budgetData?.hotelMonthlyBudgets]);

  const handleDelete = useCallback(
    (id: string) => {
      deleteMonthlyBudget({
        variables: { deleteMonthlyBudgetId: id },
        update: (cache) => {
          const normalizedId = cache.identify({
            id,
            __typename: 'MonthlyBudget',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      }).then(() => refetch());
      setSelected((prev) => prev.filter((i) => i !== id));
    },
    [deleteMonthlyBudget, refetch]
  );

  const handleUpdate = useCallback(
    (data: Partial<MonthlyBudget>) => {
      if (data.id) {
        updateMonthlyBudget({
          variables: {
            updateMonthlyBudgetId: data.id,
            monthlyBudget: {
              budget_sold: data.budget_sold,
              budget_adr: data.budget_adr,
            },
          },
        }).then(() => refetch());
      } else {
        createMonthlyBudget({
          variables: { monthlyBudget: data as CreateMonthlyBudgetInput },
        }).then(() => refetch());
      }
    },
    [updateMonthlyBudget, createMonthlyBudget, refetch]
  );

  const monthlyBudgets = useMemo(() => {
    return months.map((month) => {
      const existingBudget = budgetData?.hotelMonthlyBudgets?.find(
        (b) => b?.month === month.value
      );
      return {
        ...existingBudget,
        month,
      };
    });
  }, [months, budgetData?.hotelMonthlyBudgets]);

  if (loadingBudget) {
    return (
      <div className='flex justify-center items-center h-64'>
        <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900 w-10 h-10' />
        <h2 className='ml-4 text-2xl'>Loading Budget...</h2>
      </div>
    );
  }

  return (
    <div className='border-t border-gray-200 mt-6'>
      <DataTableContainer>
        <table className='w-[70%] mx-auto shadow rounded mt-4'>
          <thead>
            <tr className='border-b border-gray-200'>
              <th className='w-24'>
                <div className='flex items-center ml-10'>
                  <input
                    type='checkbox'
                    onChange={handleSelectAllRows}
                    checked={
                      selected.length ===
                        budgetData?.hotelMonthlyBudgets?.length &&
                      selected.length > 0
                    }
                    aria-label='Select all months'
                  />
                  {selected.length > 1 && (
                    <button
                      className='ml-2 text-xl text-red-700'
                      onClick={() => selected.forEach(handleDelete)}
                      data-tip='Delete Selected'
                      aria-label='Delete selected months'
                    >
                      <MdDeleteForever />
                    </button>
                  )}
                </div>
              </th>
              <th className='py-2 text-gray-800'>Month</th>
              <th className='py-2 text-gray-800'>Sold</th>
              <th className='py-2 text-gray-800'>ADR</th>
              <th className='py-2 text-gray-800'>Revenue</th>
            </tr>
          </thead>
          <tbody>
            {monthlyBudgets.map((budget) => (
              <MonthRow
                key={budget.month.value}
                month={budget.month}
                // @ts-ignore
                data={budget}
                userId={user?.id || ''}
                // @ts-ignore
                hotelId={hotel?.hotel_id}
                selected={selected.includes(budget.id || '')}
                onSelect={handleRowSelect}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
            ))}
          </tbody>
        </table>
      </DataTableContainer>
      <ReactTooltip />
    </div>
  );
}

export default Budget;

import {
  AgendaItemNote,
  Maybe,
  Meeting,
  MeetingCategory,
  MeetingEmail,
  MeetingService,
  TeamMember,
  TeamMemberActive,
} from '../../../graphql/types';

import { ReportUrls } from './report-urls';
import { displayDate } from '../../../helpers/dateHelpers';

export * as Email from './email';

export type CreateMailtoArgs = {
  from: string;
  meeting: Meeting;
  meetingTools: MeetingService;
};

export const REPORT_FROM_EMAIL = 'reports@mail.rmws.bwhhotelgroup.com';

export const createMailtoLink = ({
  from,
  meeting,
  meetingTools,
}: CreateMailtoArgs) => {
  const recipients = sendTo(meeting, meetingTools.hotelTeams);
  const emailSubject = subject(meeting);
  const reportLink = shareLink(meeting.id!);

  const mailUrl =
    `mailto:${recipients.join(';')}?cc=${from}&subject=` +
    encodeURIComponent(emailSubject);

  const messageBody =
    `<b>Please click the <a href="${reportLink}">link</a> to view your report:</b>` +
    '<br>' +
    reportLink +
    '\n' +
    meetingDetails(meeting, meetingTools);

  return { emailSubject, mailUrl, messageBody, recipients, reportLink };
};

const activeTeam = (member: Maybe<TeamMember>) => {
  return member?.active === TeamMemberActive.Active;
};

const emailPref = (member: Maybe<TeamMember>, meeting: Maybe<Meeting>) => {
  return (
    member?.emailPref === MeetingEmail.All ||
    member?.emailPref === meeting?.category
  );
};

const listAgenda = (
  ids: string[],
  meetingTools: MeetingService,
  itemNotes?: Maybe<Maybe<AgendaItemNote>[]>
) => {
  if (ids.length > 0) {
    const items = ids
      .map((id) => {
        const agendaItem = meetingTools.agendaItems?.find(
          (item) => String(item?.id) === id
        );
        const note = itemNotes?.find((item) => String(item?.itemId) === id);
        return `<li>${agendaItem?.name} ${
          note && note.notes ? `<ul><li>${note.notes}</li></ul>` : ''
        }</li>`;
      })
      .filter((item) => item !== undefined)
      .join('\n');
    return `<ul>${items}</ul>`;
  }
  return '';
};

const listAttendees = (ids: string[], meetingTools: MeetingService) => {
  if (ids.length > 0) {
    const items = ids
      .map((id) => {
        const attendee = meetingTools?.hotelTeams?.find(
          (item) => String(item?.id) === id
        );
        return `<li>${attendee?.name}</li>`;
      })
      .filter((item) => item !== undefined)
      .join('\n');
    return `<ul>${items}</ul>`;
  }
  return '';
};

const meetingDetails = (
  meeting: Maybe<Meeting>,
  meetingTools: Maybe<MeetingService>
) => {
  let details = '';
  if (!meeting || !meetingTools) {
    return details;
  }

  if (meeting.category === MeetingCategory.Weekly) {
    details =
      details +
      `<h3>--- Agenda ---</h3>` +
      '\n' +
      listAgenda(
        meeting.agendaItems as string[],
        meetingTools,
        meeting.agendaItemNotes
      ) +
      '\n' +
      `<h3>--- Attendees ---</h3>` +
      '\n' +
      listAttendees(meeting.attendees as string[], meetingTools) +
      '\n';
  }

  details = details + `<h3>--- Notes ---</h3>\n` + notesText(meeting) + '\n';

  return details;
};

const notesText = (meeting: Meeting) => {
  return meeting.notes;
};

const sendTo = (meeting: Maybe<Meeting>, to?: Maybe<Maybe<TeamMember>[]>) => {
  if (to) {
    return to
      .filter(activeTeam)
      .filter((member) => emailPref(member, meeting))
      .map((member) => member?.email);
  }
  return [];
};

const shareLink = (meetingId: string) => {
  return ReportUrls.createShareUrl(meetingId);
};

const subject = (meeting: Meeting) => {
  const { brandCode, meetingDate } = meeting;
  return `${brandCode} | RM ${subjectCategory(
    meeting
  )} Update for ${displayDate(meetingDate || 'today')}`;
};

const subjectCategory = (meeting: Meeting) => {
  switch (meeting.category) {
    case MeetingCategory.Weekly:
      return 'Weekly';
    case MeetingCategory.Adhoc:
      return 'Ad Hoc';
    default:
      return 'Daily';
  }
};

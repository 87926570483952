import { useCallback, useEffect, useState } from 'react';
import {
  useGetUserOptionQuery,
  useUpdateUserOptionMutation,
} from '../../../features/user-options/gql/_gen_/user-options.gql';

import { Editor } from '@tiptap/react';

interface UseNotepadProps {
  brandCode?: string;
  userId?: string | null;
}

const useNotepad = ({ brandCode, userId }: UseNotepadProps) => {
  const [notepad, setNotepad] = useState('');
  const [savedValue, setSavedValue] = useState('');
  const [editor, setEditor] = useState<Editor | null>(null);

  const skip =
    !brandCode ||
    brandCode === undefined ||
    brandCode === null ||
    brandCode === 'undefined' ||
    !userId ||
    userId === undefined ||
    userId === null ||
    userId === 'undefined';

  const { data, loading } = useGetUserOptionQuery({
    skip,
    variables: {
      brandCode: String(brandCode),
      userId: String(userId),
    },
  });

  const [updateUserOption, { loading: loadingSave }] =
    useUpdateUserOptionMutation({
      onCompleted: (data) => {
        if (data?.updateUserOption?.appNotes) {
          setNotepad(data?.updateUserOption?.appNotes);
          setSavedValue(data?.updateUserOption?.appNotes);
        }
      },
    });

  useEffect(() => {
    if (data) {
      if (data.getUserOption?.appNotes) {
        setNotepad(data.getUserOption?.appNotes);
        setSavedValue(data.getUserOption?.appNotes);
      } else {
        setNotepad('');
        setSavedValue('');
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (loadingSave === false && editor) {
      editor.commands.focus('end');
    }
  }, [loadingSave, editor]);

  const saveNotes = useCallback(async () => {
    if (brandCode && userId && editor) {
      const content = editor.getHTML();
      await updateUserOption({
        variables: {
          brandCode,
          userId,
          appNotes: content,
        },
      });
    }
  }, [brandCode, userId, editor, updateUserOption]);

  const setEditorInstance = useCallback((newEditor: Editor | null) => {
    setEditor(newEditor);
  }, []);

  return {
    notepad,
    loadingSave,
    setNotepad,
    saveNotes,
    savedValue,
    setEditorInstance,
  };
};

export { useNotepad };

import PropertyHeader from '../../components/PropertyHeader';
import ReactTooltip from 'react-tooltip';
import { Routes } from './components/routes';
import { Tabs } from './components/tabs';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../context/userContext';

export const AdminDashboard = () => {
  const location = useLocation();
  const { isManager } = useUser();

  if (!isManager) {
    return null;
  }

  return (
    <section className='py-0 bg-gray-100'>
      <div className='px-0 mx-auto'>
        <div className='bg-white rounded'>
          <div className='pb-2 px-4'>
            <div className='mb-1'>
              <PropertyHeader page={location.pathname} />
            </div>
            <div className='my-2'>
              <Tabs />
            </div>
            <div>
              <Routes />
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </section>
  );
};

import { useEffect, useState } from 'react';

import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';

type FeatureFlags = {
  'meeting-notes-upgrade': { enabled: boolean; userEmails?: string[] };
  'show-admin-menu': { enabled: boolean; userEmails: string[] };
};

const flagsUrl = `${process.env.REACT_APP_API_URL}/flags`;

export const useFeatureFlags = () => {
  const { oktaAuth } = useOktaAuth();
  const [flags, setFlags] = useState<FeatureFlags>();

  const token = oktaAuth.getAccessToken();

  const flagCheck = (flag: keyof FeatureFlags, userEmail?: string) => {
    const targetFlag = flags?.[flag];

    if (!targetFlag?.enabled) {
      return false;
    }

    if (!targetFlag.userEmails) {
      return true;
    }

    if (!userEmail) {
      return false;
    }

    const cleanedEmail = userEmail.toLowerCase().trim();

    return targetFlag.userEmails.includes(cleanedEmail);
  };

  async function fetchFlags() {
    if (!token) return;

    const response = await axios.get(flagsUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setFlags(response.data);
  }

  useEffect(() => {
    fetchFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, token]);

  return { flagCheck, flags };
};

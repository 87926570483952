import { MenuItem, menuItems } from './menu-items';

import MeetingTools from '../meetings/components/meeting-tools';
import MeetingToolsNew from '../meetings/components/meeting-tools-new';
import NavButton from './components/nav-button';
import NavDropdown from './components/nav-dropdown';
import NavLogo from './components/nav-logo';
import { NotepadButton } from './components/notepad-button';
import { NotepadButton as NotepadButtonNew } from './components/notepad-button-new';
import UserMenu from './components/user-menu';
import { useHotel } from '../../context/hotelContext';
import { useIsShared } from '../reports/hooks/use-report-location';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../context/userContext';

function Navbar() {
  const location = useLocation();
  const { isManager, oktaUser, user, showNewNotes } = useUser();
  const { hotel } = useHotel();
  const { isShared } = useIsShared();

  const isActive = (menuKey: string) => {
    return location.pathname.includes(menuKey);
  };

  const filterAdmin = (item: MenuItem) => {
    if (item.adminOnly && !isManager) return false;
    return true;
  };

  return (
    <section>
      <nav className='relative'>
        <div className='p-3 flex items-center bg-blue-900'>
          <NavLogo />
          {!isShared && (
            <ul className='hidden md:flex'>
              {menuItems.filter(filterAdmin).map((item) => {
                const btnProps = { ...item, isActive: isActive(item.key) };
                if (item.tabItems && item.tabItems.length > 0) {
                  return <NavDropdown {...btnProps} />;
                } else {
                  return <NavButton {...btnProps} />;
                }
              })}
            </ul>
          )}
          <div className='hidden md:block md:justify-end md:items-center md:space-x-6 mr-6 ml-auto'>
            <div className='flex items-center'>
              {!isShared && oktaUser && oktaUser.email && (
                <>
                  {showNewNotes ? (
                    <>
                      <NotepadButtonNew
                        brandCode={hotel?.brand_code}
                        userId={user?.id}
                      />
                      <MeetingToolsNew />
                    </>
                  ) : (
                    <>
                      <NotepadButton
                        brandCode={hotel?.brand_code}
                        userId={user?.id}
                      />
                      <MeetingTools />
                    </>
                  )}

                  <UserMenu
                    email={oktaUser.email}
                    name={oktaUser.name}
                    role={oktaUser.userRole}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Navbar;

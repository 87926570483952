import { User, UserLookupType, UserRole } from '../graphql/types';
import { createContext, useContext, useEffect, useState } from 'react';

import { useFeatureFlags } from '../hooks/use-feature-flags';
import { useGetUserLazyQuery } from '../features/user/gql/_gen_/user.gql';
import { useIp } from '../features/tracking/hooks/use-ip';
import { useIsShared } from '../features/reports/hooks/use-report-location';
import { useOktaAuth } from '@okta/okta-react';

export type State = User | null | undefined;
export type OktaUserState = OktaUserCustom | undefined;
type Dispatch = (user: State) => void;

// Copies IDToken["claims"] from '@okta/okta-auth-js', but adds
// userRole and mw_groups to the object.
type OktaUserCustom = {
  at_hash?: string;
  aud?: string;
  auth_time?: number;
  email?: string;
  email_verified?: boolean;
  exp?: number;
  family_name?: string;
  given_name?: string;
  iat?: number;
  iss?: string;
  jti?: string;
  locale?: string;
  login_ID?: string;
  mw_groups?: string[];
  name?: string;
  nonce?: string;
  preferred_username?: string;
  sub: string;
  updated_at?: number;
  userRole?: UserRole;
  ver?: number;
  zoneinfo?: string;
};

type UserContextProps = {
  isManager: boolean;
  oktaUser: OktaUserState;
  setUser: Dispatch;
  showNewNotes: boolean;
  user: State;
  userIp: string | null;
};
type UserProviderProps = { children: React.ReactNode };

const UserContext = createContext<UserContextProps | undefined>(undefined);

function UserProvider({ children }: UserProviderProps) {
  const { authState } = useOktaAuth();
  const [oktaUser, setOktaUser] = useState<OktaUserState>(undefined);
  const [user, setUser] = useState<State>(undefined);
  const { ip: userIp } = useIp();
  const { isShared } = useIsShared();
  const { flagCheck } = useFeatureFlags();

  const isManager = flagCheck('show-admin-menu', oktaUser?.email);
  const showNewNotes = flagCheck('meeting-notes-upgrade', oktaUser?.email);

  const [getUser, { data }] = useGetUserLazyQuery();

  useEffect(() => {
    if (authState && isShared === false) {
      const oktaUser = authState?.idToken?.claims;
      if (oktaUser) {
        setOktaUser({
          ...oktaUser,
          ...(user?.role && { userRole: user?.role }),
        });
      } else {
        setOktaUser(undefined);
      }

      if (oktaUser && oktaUser.sub) {
        getUser({
          variables: {
            lookup: {
              lookupColumn: UserLookupType.OktaUid,
              lookupValue: oktaUser.sub,
            },
          },
        });
        // TODO - If we cannot find the user in our database, should we create one?
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, user]);

  useEffect(() => {
    if (data) {
      setUser(data.getUser as User);
    }
  }, [data]);

  const value = {
    isManager,
    oktaUser,
    setUser,
    showNewNotes,
    user,
    userIp,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

export { UserProvider, useUser };

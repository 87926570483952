import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Underline as UnderlineIcon,
} from 'lucide-react';
import { Card, CardContent } from './ui/card';
import { EditorContent, useEditor } from '@tiptap/react';
import {
  UseMeetingNotesProps,
  useMeetingNotes,
} from '../features/notes/hooks/use-notes-new';
import { getObject, saveObject } from '../helpers/localStorageHelper';
import { memo, useCallback, useEffect, useState } from 'react';

import { BtnInsertNotepad } from '../features/notes/components/btn-insert-notepad';
import { BtnInsertNotes } from '../features/notes/components/btn-insert-notes';
import { Button } from './ui/button';
import { Meeting } from '../graphql/types';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import clsx from 'clsx';
import { toast } from './ui/use-toast';
import { today } from '../helpers/dateHelpers';
import { useDebouncedCallback } from 'use-debounce';
import { useIsShared } from '../features/reports/hooks/use-report-location';

interface MeetingNotesProps extends UseMeetingNotesProps {
  parentName: string;
  setMeeting?: (meeting: Meeting) => void;
}

const MeetingNotes = memo(function MeetingNotes(props: MeetingNotesProps) {
  const [isMounted, setIsMounted] = useState(true);
  const { isShared } = useIsShared();
  const { parentName, ...rest } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { handleSave, notes, notepad, prevNotes } = useMeetingNotes({
    ...rest,
  });

  const flexWidth = parentName === 'meeting-tools' ? 'max-w-[400px]' : 'w-full';

  const setCursorToEnd = useCallback((editor) => {
    if (editor) {
      const end = editor.state?.doc.content.size;
      editor.commands?.setTextSelection(end);
    }
  }, []);

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: notes,
    editable: !isShared,
    autofocus: true,
    editorProps: {
      attributes: {
        class: `outline-none ${flexWidth} min-h-[200px] p-4 leading-extra-tight`,
      },
      handleDOMEvents: {
        focus: (view, event) => {
          setCursorToEnd(view.state.tr);
          return false;
        },
      },
    },
    onCreate: ({ editor }) => {
      setCursorToEnd(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      debouncedSave(content);
    },
  });

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  useEffect(() => {
    if (editor && props.brandCode) {
      const savedContent = loadFromLocalStorage();
      if (notes) {
        editor.commands.setContent(notes);
      } else if (savedContent) {
        editor.commands.setContent(savedContent);
      } else {
        editor.commands.setContent('');
      }
      setCursorToEnd(editor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notes, props.brandCode, setCursorToEnd]);

  const handleInsert = (source: 'notepad' | 'prevNotes') => {
    const toInsert = source === 'notepad' ? notepad : prevNotes;

    if (editor) {
      const { state } = editor;
      const { tr } = state;
      const { selection } = tr;
      const { from } = selection;

      if (editor.isEmpty) {
        // The editor is empty, so just insert the new notes
        editor.commands.setContent(toInsert);
      } else {
        // Insert the content at the current cursor position
        editor.chain().focus().insertContent(toInsert).run();
      }

      // Move the cursor to the end of the inserted content
      const newPosition = from + toInsert.length;
      editor.commands.setTextSelection(newPosition);
    }
  };

  const saveToLocalStorage = (content: string) => {
    saveObject(`meeting-notes-${rest.brandCode}`, { content, date: today() });
  };

  const loadFromLocalStorage = () => {
    const savedNotes = getObject(`meeting-notes-${rest.brandCode}`) as {
      content: string;
      date: string;
    };
    if (savedNotes.date !== today()) return '';
    return savedNotes.content;
  };

  const saveNotes = async (content: string) => {
    if (!isMounted) return; // Don't proceed if component is unmounted
    setIsSaving(true);
    try {
      await handleSave(content);
      saveToLocalStorage(content);
      if (isMounted) {
        // Check again before updating state
        toast({
          title: 'Notes saved',
          description: 'Your meeting notes have been saved successfully.',
        });
      }
    } catch (error) {
      console.error('Failed to save notes:', error);
      if (isMounted) {
        // Check before updating state
        toast({
          title: 'Error saving notes',
          description:
            "There was a problem saving your notes. They've been stored locally.",
          variant: 'destructive',
        });
      }
    } finally {
      if (isMounted) setIsSaving(false); // Check before updating state
    }
  };

  const debouncedSave = useDebouncedCallback(async (content: string) => {
    await saveNotes(content);
  }, 2000);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    if (editor && props.brandCode) {
      const savedContent = loadFromLocalStorage();
      if (notes) {
        editor.commands.setContent(notes);
      } else if (savedContent) {
        editor.commands.setContent(savedContent);
      } else {
        editor.commands.setContent('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notes, props.brandCode]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (editor && !editor.isEmpty && editor.getHTML() !== notes) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [editor, notes]);

  useEffect(() => {
    if (editor) {
      editor.on('update', ({ editor }) => {
        const content = editor.getHTML();
        debouncedSave(content);
      });
    }
  }, [editor, debouncedSave]);

  const handleManualSave = async () => {
    if (editor) {
      const content = editor.getHTML();
      await saveNotes(content);
      setCursorToEnd(editor);
    }
  };

  return (
    <Card className='w-full mx-auto'>
      <CardContent className='p-0'>
        <div className='p-4'>
          {!isShared && (
            <div className='flex gap-2 flex-wrap'>
              <Button
                variant='outline'
                size='icon'
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'bg-muted' : ''}
              >
                <Bold className='h-4 w-4' />
              </Button>
              <Button
                variant='outline'
                size='icon'
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'bg-muted' : ''}
              >
                <Italic className='h-4 w-4' />
              </Button>
              <Button
                variant='outline'
                size='icon'
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                className={editor?.isActive('underline') ? 'bg-muted' : ''}
              >
                <UnderlineIcon className='h-4 w-4' />
              </Button>
              <Button
                variant='outline'
                size='icon'
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={editor?.isActive('bulletList') ? 'bg-muted' : ''}
              >
                <List className='h-4 w-4' />
              </Button>
              <Button
                variant='outline'
                size='icon'
                onClick={() =>
                  editor?.chain().focus().toggleOrderedList().run()
                }
                className={editor?.isActive('orderedList') ? 'bg-muted' : ''}
              >
                <ListOrdered className='h-4 w-4' />
              </Button>
              <BtnInsertNotes handleInsert={() => handleInsert('prevNotes')} />
              <BtnInsertNotepad handleInsert={() => handleInsert('notepad')} />
            </div>
          )}
        </div>
        <div className='w-full border rounded-sm mx-1'>
          <EditorContent
            editor={editor}
            className='prose prose-sm max-w-none w-full'
          />
        </div>
        <div className='flex justify-end px-4 py-1'>
          <Button
            className={clsx(isShared && 'hidden')}
            onClick={handleManualSave}
            disabled={isSaving || isShared}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
});

export default MeetingNotes;
